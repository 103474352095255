import { Button, UptownLink } from "@/components";
import { ScaledHeading, Card } from "@/components";

import Styles from "./Default.module.css";

const Courses = ({ primary, courseCards }) => {
  return (
    <section className={Styles.wrapper}>
      <ScaledHeading
        heading={primary?.heading}
        breakLines={true}
        containerClass={Styles.scaledContainer}
        textClass={Styles.scaled}
      />
      <div className={Styles.cardContainer}>
        {courseCards[0]?.items?.map((item, index) => {
          if (item?.reference_product?.url)
            return (
              <Card
                link={item?.reference_product}
                image={item?.reference_product?.data?.featured_image}
                title={item?.reference_product?.data?.card_title}
                description={item?.reference_product?.data?.card_description}
                key={index}
              />
            );
        })}
      </div>
      <div className={Styles.ctaContainer}>
        {primary?.cta_link && primary?.cta_label && (
          <Button link={primary?.cta_link} label={primary?.cta_label} />
        )}{" "}
      </div>
    </section>
  );
};

export default Courses;
