import { ScaledHeading, Card } from "@/components";
import Styles from "./Default.module.css";

const EquipmentCategory = ({ primary, equipmentCategoriesCards }) => {
	return (
		<section className={Styles.wrapper}>
			<ScaledHeading
				heading={primary?.heading}
				breakLines={true}
				containerClass={Styles.scaledContainer}
				textClass={Styles.scaled}
			/>
			<div className={Styles.cardContainer}>
				{equipmentCategoriesCards[0]?.items?.map((item, index) => {
					if (item?.reference_product?.url)
						return (
							<Card
								link={item?.reference_product}
								image={item?.reference_product?.data?.category_image}
								title={item?.reference_product?.data?.category_title}
								description={
									item?.reference_product?.data?.category_description || null
								}
								key={index}
							/>
						);
				})}
			</div>
		</section>
	);
};

export default EquipmentCategory;
