import Default from "./Default";
import Circle from "./Circle";
import Courses from "./Courses";
import Equipment from "./Equipment";
import EquipmentCategories from "./EquipmentCategories";

/**
 * @typedef {import("@prismicio/client").Content.CardGridSlice} CardGridSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<CardGridSlice>} CardGridProps
 * @param {CardGridProps}
 */

const CardGrid = ({
	slice: { items, primary, variation },
	courseCards,
	equipmentCards,
	equipmentCategoriesCards,
}) => {
	if (variation == "default")
		return <Default primary={primary} items={items} />;
	if (variation == "circles") return <Circle primary={primary} items={items} />;
	if (variation == "courses")
		return <Courses primary={primary} courseCards={courseCards} />;
	if (variation == "equipment")
		return <Equipment primary={primary} equipmentCards={equipmentCards} />;
	if (variation == "equipmentCategories")
		return (
			<EquipmentCategories
				primary={primary}
				equipmentCategoriesCards={equipmentCategoriesCards}
			/>
		);
};

export default CardGrid;
