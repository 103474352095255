import { ScaledHeading, Card } from "@/components";

import Styles from "./Default.module.css";

const Default = ({ primary, items }) => {
	return (
		<section className={Styles.wrapper}>
			<ScaledHeading
				heading={primary?.heading}
				breakLines={true}
				containerClass={Styles.scaledContainer}
				textClass={Styles.scaled}
			/>
			<div className={Styles.cardContainer}>
				{items.map((item, index) => {
					if (item.card_link.url)
						return (
							<Card
								link={item?.card_link}
								image={item?.card_image}
								title={item?.card_title}
								description={item?.card_description}
								containImage={item?.contain_image}
								key={index}
							/>
						);
				})}
			</div>
		</section>
	);
};

export default Default;
