import { PrismicNextImage } from "@prismicio/next";
import { Button, UptownLink } from "@/components";

import Styles from "./Circle.module.css";
import { PrismicRichText } from "@prismicio/react";

const Circle = ({ primary, items }) => {
  return (
    <section className={Styles.wrapper}>
      <div className={Styles.container} data-contain="true">
        <div className={Styles.headerContainer}>
          <PrismicRichText field={primary?.description} />
          <h2 className={Styles.heading}>{primary?.heading}</h2>
        </div>
        <div className={Styles.cardContainer}>
          {items.map((item, index) => {
            if (item.card_link.url)
              return (
                <UptownLink
                  link={item?.card_link}
                  className={Styles.card}
                  key={index}
                >
                  <div className={Styles.imageContainer}>
                    <PrismicNextImage
                      field={item.card_image}
                      className={Styles.image}
                    />
                  </div>

                  <h4 className={Styles.cardTitle}>{item?.card_title}</h4>
                </UptownLink>
              );
          })}
        </div>
        <div className={Styles.ctaContainer}>
          {primary?.cta_link && primary?.cta_label && (
            <Button link={primary?.cta_link} label={primary?.cta_label} />
          )}{" "}
        </div>
      </div>
    </section>
  );
};

export default Circle;
